import Vue from 'vue'
import ElementUI from 'element-ui';
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import Cookies from 'js-cookie';
import router from './router.js';
import App from './App.vue'
import './styles/common.scss'
import { url } from '@/config'
import '@/mock/'
//导入主题文件
import '@/theme/index.js'
//导入自定义的组件-小黄牛
import '@/components-xhn/index.js'
window.axios = axios;
Vue.config.productionTip = false
Vue.prototype.url = url;
Vue.use(window.AVUE, {
  size: 'mini'
});
Vue.use(ElementUI);
Vue.use(dataV);
router.beforeEach((to, from, next) => { 
  // if(to.path !== '/'){
    next();
  // }else{
  //   if (Cookies.get('canUseSystem') == 1) { //验证是否输入了正确密码
  //       next();
  //    }
  // }
    
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
