<template>
<div id="main-map-container">
    <div id="xhn-map" :style="`height:${height}px;width:${width}px;`"></div>
</div>
</template>

<script>
import * as echarts from 'echarts';
// import svg from './map-svg.svg'
import {url} from '@/config.js';
export default {
    name:'map',
    props:{
        option:{
            default:null
        },
        id:{
            default:null
        },
        height:{
            default:0
        },
        width:{
            default:0
        },
    },
    mounted(){
        this.initMap()
    },
    data(){
        return {
            myChart:null,
        }
    },
    watch:{
        option:{
            handler(val) {
                this.setMap(val)
            },
            deep:true
        }
    },
    methods:{
        initMap(){
            this.myChart = echarts.init(document.getElementById('xhn-map'));
            this.setMap(this.option);
        },
        getOption(option){
            let mapRadarData = []
            if(this.option.mapRadarData){
                try{
                    mapRadarData = JSON.parse(this.option.mapRadarData.replace(/[\r\n]/g,'').replace(/\s*/g,'').replaceAll('},]','}]').replaceAll('],]',']]'))
                }catch(err){

                }
                // [
                //     {
                //         "name":"隆阳区",
                //         "value":[99.172071,25.126888],
                //         "itemStyle":{
                //             "color":"#f5a623"
                //         },
                //         "rippleEffect":{
                //             "color":"#f5a623"
                //         }
                //     },
                //     {
                //         "name":"昌宁县",
                //         "value":[99.611617,24.833985]
                //     },
                //     {
                //         "name":"施甸县",
                //         "value":[99.233529,24.665698]
                //     },
                //     {
                //         "name":"龙陵县",
                //         "value":[98.89575,24.592547]
                //     },
                //     {
                //         "name":"腾冲市",
                //         "value":[98.503765,25.023681]
                //     }
                // ]
            }
            let obj = {
                geo:[
                {
                    name: 'map1',
                    type: 'map',
                    map:`${option.mapData}`,
                    id:'map1',
                    zlevel:1,
                    itemStyle:{
                        areaColor:'#4672c9',
                        borderColor:'#5b90dd',
                        borderWidth:2,
                        shadowColor: `#022e58`,
                        shadowOffsetX: 20,
                        shadowOffsetY: 20,
                        shadowBlur: 1
                    },
                    label:{
                        show:true,
                        color:'#fff'
                    },
                    aspectScale:0.9,
                    zoom:Number(option.zoom),
                },
                {
                    name: 'map_geo2',
                    type: 'map',
                    map:`${option.mapData}`,
                    id:'map_geo2',
                    zlevel:2,
                    itemStyle:{
                        // areaColor:'#4672c9',
                        // color: {
                        //     image:``, 
                        //     // 支持为 HTMLImageElement, HTMLCanvasElement, 不支持路径字符串
                        //     repeat: `repeat` // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
                        // },
                        borderColor:this.option.showMapOuterColor,
                        borderWidth:this.option.strokeWidth,
                        shadowColor:this.option.showMapOuterShadow,
                        shadowBlur: this.option.showMapOuterShadowSize,
                    },
                    label:{
                        show:true,
                        color:'#fff'
                    },
                    aspectScale:0.9,
                    zoom:Number(option.zoom),
                }
                ],
                series: [
                    {
                        name: 'map1',
                        type: 'map',
                        map:`${option.mapData}`,
                        zoom:option.zoom,
                        id:'map1',
                        zlevel:3,
                        itemStyle:{
                            //  'transparent' || 
                            areaColor:option.areaColor,
                            borderColor:option.borderColor,
                            borderWidth:option.borderWidth,
                        },
                        label:{
                            show:true,
                            color:option.color,
                            fontSize:option.fontSize
                        },
                        emphasis:{
                            itemStyle:{
                                areaColor:option.empAreaColor,
                            },
                            label:{
                                color:option.empBorderColor,
                            },
                        },
                        select:{
                            itemStyle:{
                                areaColor:option.empAreaColor,
                            },
                            label:{
                                color:option.empBorderColor,
                            },
                        },
                        data:[
                            {
                                name:'隆阳区',
                                id:'0-0-0'
                            },
                            {
                                name:'昌宁县',
                                id:'0-0-4'
                            },
                            {
                                name:'施甸县',
                                id:'0-0-3'
                            },
                            {
                                name:'龙陵县',
                                id:'0-0-2'
                            },
                            {
                                name:'腾冲市',
                                id:'0-0-1'
                            },
                        ],
                        aspectScale:0.9,
                        tooltip:{
                            show:false
                        }
                    },
                    {
                        zlevel:5,
                        name:'schoolRadar',
                        type:'effectScatter',
                        rippleEffect:{
                            color:this.option.radarShadowColor || '#15c8f6',
                            period:6,
                            scale:3,
                        },
                        itemStyle:{
                            color:this.option.radarColor || '#15c8f6'
                        },
                        coordinateSystem:'geo',
                        symbolSize:this.option.radarSize || 32,
                        data:mapRadarData,
                        selectedMode:"single",
                    }
                ],
                tooltip : {
                    show:true,
                    formatter: (params)=>{
                        console.log(params)
                        // 示例数据格式
                        // [
                        //     {
                        //         "name":"保山市隆阳区瓦房中学",
                        //         "value":[99.0618342,25.36841287],
                        //         "info":"销售额: 205846.00元<br/> 学生人数: 5000人"
                        //     },
                        //     {
                        //         "name":"保山市智源高级中学有限公司",
                        //         "value":[99.230874,25.098222],
                        //         "info":"销售额: 55846.00元<br/> 学生人数: 2000人"
                        //     },
                        //     {
                        //         "name":"隆阳区瓦马民族中学",
                        //         "value":[98.983341073741,25.574327480927],
                        //         "info":"销售额: 209846.00元<br/> 学生人数: 2000人"
                        //     },
                        //     {
                        //         "name":"云南省保山市实验中学",
                        //         "value":[99.1852601201044,25.0324432669826],
                        //         "info":"销售额: 455846.00元<br/> 学生人数: 8000人"
                        //     },
                        //     {
                        //         "name":"保山市杨柳中学",
                        //         "value":[99.0018935,25.19075017],
                        //         "info":"销售额: 25846.00元<br/> 学生人数: 1000人"
                        //     }
                        // ]
                        return `${params.data.name}<br/>${params.data.info || ''}`
                    },
                    backgroundColor:this.option.mapTooltipSlideBackColor || "#15c8f6",
                    textStyle:{
                        fontSize:30,
                        color:this.option.mapTooltipSlideTextColor || '#ffffff'
                    }
                },
            }
            if(this.option.showMapOuter !== true){
                obj.geo.pop()
            }
            if(this.option.mapRadar !== true){
                obj.series.pop()
            }
            if(this.option.mapLines){
                obj.series = obj.series.concat(this.getLineMap())
            }
            if(this.option.hideMap == true){
                obj.series.shift();
                obj.geo.shift();
                obj.geo[0] = {
                    ...obj.geo[0],
                    // name: 'map_geo2',
                    // type: 'map',
                    // map:`${option.mapData}`,
                    // id:'map_geo2',
                    // zlevel:2,
                    itemStyle:{
                        areaColor:'#00000000',
                        color: {
                            image:``, 
                            // 支持为 HTMLImageElement, HTMLCanvasElement, 不支持路径字符串
                            repeat: `repeat` // 是否平铺，可以是 'repeat-x', 'repeat-y', 'no-repeat'
                        },
                        borderColor:'#00000000',
                        // borderWidth:this.option.strokeWidth,
                        shadowColor:'#00000000',
                        // shadowBlur: this.option.showMapOuterShadowSize,
                    },
                    label:{
                        show:false,
                        color:'#fff'
                    },
                    // aspectScale:0.9,
                    // zoom:Number(option.zoom),
                }
            }
            return obj
        },
        getLineMap(){
            //飞线图数据
            // [
            //     [
            //         {"name":"隆阳区","point":[99.172071,25.126888]},
            //         {"name":"昌宁县","point":[99.611617,24.833985]},
            //         {"name":"施甸县","point":[99.233529,24.665698]},
            //         {"name":"龙陵县","point":[98.89575,24.592547]},
            //         {"name":"腾冲市","point":[98.503765,25.023681]}
            //     ],
            //     [
            //         {"name":"腾冲市","point":[98.503765,25.023681]},
            //         {"name":"隆阳区","point":[99.172071,25.126888]},
            //         {"name":"龙陵县","point":[98.89575,24.592547]}
            //     ]
            // ]
            let arr = []
            try{
                arr = JSON.parse(this.option.mapLinesData.replace(/[\r\n]/g,'').replace(/\s*/g,'').replaceAll('},]','}]').replaceAll('],]',']]'))
            }catch(err){

            }
            //数据处理
            let convertData = function(data) {
                let res = [];
                if(Array.isArray(data[0])){//如果获取到的数据是双层嵌套,则说明有多个中心点发散
                    for(let i = 0; i < data.length;i++){
                        for(let j = 1;j < data[i].length;j++){
                            res.push([
                                {
                                    coord: data[i][0].point,
                                },
                                {
                                    coord: data[i][j].point,
                                }
                            ]);
                        }
                    }
                }else{
                    for(let i = 1; i < data.length; i++) {
                        res.push([
                            {
                                coord: data[0].point,
                            },
                            {
                                coord: data[i].point,
                            }
                        ]);
                    }
                }
                return res;
            };
            return {
                        type: 'lines',
                        zlevel: 4,
                        effect: {
                            show: true,
                            period: 4, //箭头指向速度，值越小速度越快
                            trailLength: 0.1, //特效尾迹长度[0,1]值越大，尾迹越长重
                            symbol: this.option.linesIcon||'arrow', //箭头图标
                            symbolSize: 17, //图标大小
                        },
                        lineStyle: {
                            normal: {
                                width: this.option.linesWidth || 4, //尾迹线条宽度
                                opacity: .5, //尾迹线条透明度
                                curveness: .3, //尾迹线条曲直度
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: this.option.linesColor || '#1899FD' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: this.option.linesColor || '#1899FD' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                shadowColor: this.option.linesShadowColor || 'rgba(24, 153, 253, 0.5)',
                                shadowBlur: 10
                            },
                        },
                        data: convertData(arr)
                    }
        },
        startInterval(option,mapOption){
            let myChart = this.myChart;
            if(option.mapTooltipSlideshow){
                let index = 0;
                let seriesIndex = 1;
                mapOption.series.map((item,index2)=>{
                    if(item.name == 'schoolRadar'){
                        seriesIndex = index2
                    }
                })
                if(this.radarIntervalId){
                    clearInterval(this.radarIntervalId)
                }
                this.radarIntervalId = setInterval(()=>{
                    myChart.dispatchAction({
                        type:'showTip',
                        seriesIndex:seriesIndex,
                        dataIndex:index
                    })
                    index += 1;
                    if(index >= mapOption.series[seriesIndex].data.length){
                        index = 0
                    }
                },option.mapTooltipSlideSpeed || 3000)
            }else{
                if(this.radarIntervalId){
                    clearInterval(this.radarIntervalId)
                    this.radarIntervalId = null;
                }
            }
        },
        setMap(option){
            //获取地图数据
            let _this = this
            axios.get(`${url}${option.mapData.replace('/visual-api/','')}`)
            .then(function (response) {
                echarts.registerMap(`${option.mapData}`, response.data);
                _this.myChart.clear();
                let arr = document.querySelectorAll('#xhn-map canvas');
                for(let i = 0;i <arr.length;i++ ){
                    arr[i].getContext("2d").clearRect(0,0,arr[i].width,arr[i].height);
                };
                _this.myChart.setOption(_this.getOption(option));

                //如果开启了雷达数据轮播,则启动一个计时器
                _this.startInterval(option,_this.getOption(option))
            });
        }
    }
}
</script>

<style type="text/css">
    #main-map-container,#xhn-map{
        position: relative;
    }
    #xhn-map>div:first-child{
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    #xhn-map>div:last-child{
        /* min-width: 400px !important; */
    }
    #xhn-map>div canvas{
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
    }
</style>